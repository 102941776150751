import api from './request';

// 查询资源列表
export const getResourceList = (data: {
  projectId?: number;
  resourceName?: string;
  page?: Basic.Page;
}) => api.post<Resource.ListData>('/auth-admin/resources/query', data);

// 更新/新增
export const createOrUpdateResource = (data: Resource.ResourceItem) =>
  api.post('/auth-admin/resources/createOrUpdate', data);

// 详情
export const getResourceInfo = (resourceId: number) =>
  api.post('/auth-admin/resources/info', { resourceId });

// 删除资源
export const deleteResource = (data: { projectId: number; resourceId: number }) =>
  api.post<{ success: number }>('/auth-admin/resources/delete', data);
