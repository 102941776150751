export const resourceStatusText = {
  0: '禁用',
  1: '可用'
};

export const resourceStatusColor = {
  0: 'warning',
  1: 'success'
};
// 资源类型

export const resourceTypeList = [
  {
    value: 1,
    label: '一级菜单'
  },
  {
    value: 2,
    label: '二级菜单'
  },
  {
    value: 3,
    label: '独立页面'
  },
  {
    value: 4,
    label: '接口'
  }
];

export const resourceTypeTextMap = new Map([
  [1, '一级菜单'],
  [2, '二级菜单'],
  [3, '独立页面'],
  [4, '接口']
]);
